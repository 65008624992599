@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url('https://fonts.googleapis.com/css?family=Roboto');

body { 
 
} 
 
.navbar { 
  margin-bottom: 0; 
} 
.navbar-toggler { 
  margin-top: 0.5em; 
} 
#navbarHeader { 
  background-color: #F0F0F0; 
} 
 
.jumbotron { 
  padding-top: 15rem; 
  padding-bottom: 6rem; 
  margin-bottom: 0; 
  background-color: #fff; 
} 
 
.jumbotron p:last-child { 
  margin-bottom: 0; 
} 
 
.jumbotron-heading { 
  font-weight: 500; 
  text-shadow: 0px 1px 1px #fff; 
} 
#hero-homepage { 
  width:100%; 
  min-height: 46rem; 
  background-image: url(/assets/images/hero-homepage.png); 
  background-repeat: no-repeat; 
  background-position: top; 
  background-size:cover; 
  border-radius:0; 
//  border-bottom: 1px solid #ddd; 
} 
.jumbotron h5 { 
  color:white; 
  text-shadow:2px 2px #333; 
} 
 
.album { 
   /* Can be removed; just added for demo purposes */ 
  padding-top: 3rem; 
  padding-bottom: 3rem; 
  background-color: #f7f7f7; 
} 
 
.card { 
  float: left; 
  width: 33.333%; 
  padding: .75rem; 
  margin-bottom: 2rem; 
  border: 0; 
} 
 
.card > img { 
  margin-bottom: .75rem; 
} 
 
.card-text { 
  font-size: 85%; 
} 
 
/*flash*/ 
 
.hidden { display: none; } 
 
.alert-error { 
  background-color: #f2dede; 
  border-color: #eed3d7; 
  color: #b94a48; 
  text-align: left; 
} 
 
.alert-alert { 
  background-color: #f2dede; 
  border-color: #eed3d7; 
  color: #b94a48; 
  text-align: left; 
} 
 
.alert-success { 
  background-color: #dff0d8; 
  border-color: #d6e9c6; 
  color: #468847; 
  text-align: left; 
} 
 
.alert-notice { 
  background-color: #dff0d8; 
  border-color: #d6e9c6; 
  color: #468847; 
  text-align: left; 
} 
 
/* MARKETING CONTENT 
-------------------------------------------------- */ 
 
/* Center align the text within the three columns below the carousel */ 
.marketing .col-lg-4 { 
  margin-bottom: 1.5rem; 
  text-align: center; 
} 
.marketing h2 { 
  font-weight: normal; 
} 
.marketing .col-lg-4 p { 
  margin-right: .75rem; 
  margin-left: .75rem; 
} 
 
 
/* Featurettes 
------------------------- */ 
 
.featurette-divider { 
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */ 
} 
 
.featurette { 
//  margin-top: 3em; 
} 
 
/* Thin out the marketing headings */ 
.featurette-heading { 
  font-weight: 300; 
  line-height: 1; 
  letter-spacing: -.05rem; 
  margin-top: 1em; 
} 
 
footer { 
  border-top: 1px solid #ddd; 
  padding-top: 3rem; 
  padding-bottom: 3rem; 
} 
 
footer p { 
  margin-bottom: .25rem; 
} 
 
// Extra small devices (portrait phones, less than 576px) 
// No media query since this is the default in Bootstrap 
 
// Small devices (landscape phones, 576px and up) 
@media (min-width: 576px) {  
  .navbar-toggler { 
    margin-top: 1em; 
  } 
  .jumbotron { 
    padding-top: 14rem; 
  } 
  .featurette-heading { 
    margin-top: 4em; 
  } 
  #contact-section { 
    margin-top: 3em; 
  } 
} 
 
// Medium devices (tablets, 768px and up) 
@media (min-width: 768px) {  } 
 
// Large devices (desktops, 992px and up) 
@media (min-width: 992px) {  } 
 
// Extra large devices (large desktops, 1200px and up) 
@media (min-width: 1200px) {  } 